
import type { ComputedRef } from 'vue'
import { watch, computed, defineComponent, ref } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import { useRoute, useRouter } from 'vue-router'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import RouterTreeNode from '@/components/views/sitemap/RouterTreeNode.vue'
import PageContentSidebarList from '@/components/layout/pageContent/sidebar/PageContentSidebarList.vue'
import type { PageContentSidebarItem } from '@/definitions/_layout/pageContent/sidebar/types'
import { capitalize } from '@/services/utils'
import { baseRoute } from '@/router/routes/base'
import { otherRoutes } from '@/router/routes/base/otherRoutes'
import { authRoutes } from '@/router/routes/auth'
import { uiRoutes } from '@/router/routes/ui'
import { survey } from '@/router/routes/survey'
import { otherPages } from '@/router/routes/otherPages'
import _ from 'lodash'

export default defineComponent({
  components: {
    PageContent,
    RouterTreeNode,
    PageContentSidebarList,
  },
  setup() {
    const route = useRoute()
    const currentPageName = computed(() => route.params.page)
    const showAdminRoutes = ref(false)
    const params = {
      id: 1,
      team: 'support',
      year: 2020,
      month: 5,
    }

    const transformPath = (oldPath: string) => {
      if (oldPath.includes(':')) {
        const indexOfSeparate = oldPath.indexOf(':')
        oldPath = oldPath.slice(0, indexOfSeparate - 1)
      }
      return oldPath
    }

    const generalRoutesList = [
      ...(baseRoute.children || []),
      ...authRoutes,
    ]

    const otherRoutesList = [
      ...survey,
      ...otherRoutes,
      ...uiRoutes,
      ...otherPages,
    ]

    const filterViewList = (arr: Array<any>) => {
      return arr.map((route) => {
        const renamePath = (arg: string) => {
          if (arg.includes('/')) {
            const indexOfSeparate = arg.indexOf('/')
            arg = arg.slice(indexOfSeparate + 1)
          }
          return capitalize(arg)
        }

        return {
          title: (route.meta?.title || renamePath(route.path)) as string,
          route: `/sitemap/${String(route.name)}`,
        }
      })
    }

    const viewsList: ComputedRef<PageContentSidebarItem[]> = computed(() => {
      return filterViewList(generalRoutesList)
    })

    const secondaryViewsList: ComputedRef<PageContentSidebarItem[]> = computed(() => {
      return filterViewList(otherRoutesList)
    })

    const currentPageRoute = computed(
      () => [...generalRoutesList, ...otherRoutesList].find((route) => route.name === currentPageName.value)
    )
    const currentPageChildren = computed(() =>
      currentPageRoute.value?.children?.map(item => ({ ...item, path: transformPath(item.path) }))
    )
    const pageTitle = computed(
      () => {
        if (result.value.length) {
          return 'Search result:'
        }
        return currentPageRoute.value?.meta?.title || capitalize(`${currentPageRoute.value?.path.slice(1)}`)
      }
    )

    // search
    const search = ref('')
    const router = useRouter()
    const result = ref<RouteRecordRaw[]>([])
    const filterRoutes = (routes: RouteRecordRaw[], query: string): RouteRecordRaw[] => {
      const cloneRoutes = _.cloneDeep(routes)
      return cloneRoutes
        .filter(route => {
          const matches = `${route.meta?.title}`?.toLowerCase().includes(query.toLowerCase())
          if (route.children) {
            route.children = filterRoutes(route.children, query)
            return matches || route.children.length > 0
          }
          return matches
        })
    }

    watch(() => search.value, () => {
      if (search.value && search.value.length > 1) {
        result.value = filterRoutes(router.options.routes, search.value)
      } else {
        result.value = []
      }
    })

    watch(() => route.path, () => {
      search.value = ''
    })

    return {
      search,
      result,
      params,
      showAdminRoutes,
      viewsList,
      secondaryViewsList,
      pageTitle,
      currentPageRoute,
      currentPageChildren,
      transformPath,
    }
  },
})
