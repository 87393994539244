
import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import Highlight from '@/components/shared/Highlight.vue'
export default defineComponent({
  components: { Highlight },
  props: {
    name: {
      type: String,
    },
    children: {
      type: Array,
    },
    meta: {
      type: Object,
    },
    params: {
      type: Object,
      default: () => {},
    },
    transformPath: {
      type: Function,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const router = useRouter()
    const title = computed(() => props.meta?.title || props.name)
    const filteredChildren = computed(() => props.children?.map((item: any) => ({
      ...item,
      path: props.transformPath(item?.path),
    })))

    const loadRoute = computed(() => router.resolve({ name: props.name, params: { ...props.params } }))

    return {
      title,
      filteredChildren,
      loadRoute,
    }
  },
})
