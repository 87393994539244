import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67fec044"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "router-tree-node" }
const _hoisted_2 = {
  key: 0,
  class: "mb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_highlight = _resolveComponent("highlight")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_tree_node = _resolveComponent("router-tree-node", true)!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: {name: _ctx.loadRoute.name, params: _ctx.params},
      target: "_blank"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_highlight, {
          search: _ctx.search,
          text: _ctx.title
        }, null, 8, ["search", "text"])
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.filteredChildren)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredChildren, (childRoute) => {
            return (_openBlock(), _createBlock(_component_router_tree_node, _mergeProps({
              key: childRoute.name
            }, childRoute, {
              params: _ctx.params,
              "transform-path": _ctx.transformPath,
              search: _ctx.search
            }), null, 16, ["params", "transform-path", "search"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}